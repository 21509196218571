.groupsDropdownContainer {
    width: 256px;
}

.groupsDropdownWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.groupsDropdownLabel {
  color: var(--tds-dropdown-label-color);
  font: var(--tds-detail-05);
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
}

.inlineFlexContainer {
  width: auto;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 16px;
}

.overviewDatePickerContainer {
  height: auto;
}
