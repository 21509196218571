.tableActionsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
}

.searchField {
  width: 300px;
}

.tableContainer {
  overflow: auto;
  max-height: calc(100vh - 325px);
  
  &::-webkit-scrollbar:horizontal{
    height: 7px;
  }
}

.tableRow {
  background-color: var(--tds-table-body-row-background);
  color: var(--tds-table-color);
  display: table-row;
  transition: background-color 200ms ease 0s;

  td {
    font: var(--tds-detail-02);
    letter-spacing: var(--tds-detail-02-ls);
    display: table-cell;
    box-sizing: border-box;
    color: var(--tds-table-color);
    padding: var(--tds-spacing-element-16);
    vertical-align: middle;
    text-align: left;
    border-right: 1px solid var(--tds-table-divider);
    border-bottom: 1px solid var(--tds-table-divider);
    max-height: 64px;
  }

  td:last-of-type {
    border-right: none;
  }
}

.oddRow {
  background-color: var(--tds-grey-100) !important;
}

.evenRow {
  background-color: var(--tds-grey-50) !important;
}

.thContent {
  display: flex;
  flex-wrap: wrap;
}

.stickyLeftCol {
  position: sticky;
  left: 0;
}

.firstTableCol {
  min-width: 220px;
}

.wideTableCol {
  min-width: 256px;
  max-width: 256px;
}

.mediumTableCol {
  min-width: 164px;
  max-width: 164px;
}

.elypticText {
  max-width: calc(100% - 32px);
}
