.mainContainer {
  padding: 0px 24px 30px 24px;
  background-color: var(--tds-grey-50);
  min-height: 90vh;
}

.isolatedContainer {
  transform: none;
  isolation: isolate;
}

.pageHeader {
  align-items: center;
  background-color: var(--tds-grey-50);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 0;
  gap: 16px;
}

.typeDropdownContainer {
  min-width: 200px;
  max-width: 213px;
  margin-right: 16px;
  margin-top: 10px;
}

.leftAlignedText {
  text-align: left !important;
}

.rightAlignedText {
  text-align: right !important;
}

.centerAlignedFlex {
  display: flex;
  align-items: center;
}

.fullWidth {
  width: 100%;
}

.hiddenElement {
  display: none;
}

.clickableElement {
  cursor: pointer;
}

.padding16 {
  padding: 16px;
}

.messagePadding {
  padding: 16px 0;
}

.boldText {
  font-weight: bold;
}

.header2 {
  font: var(--tds-headline-02);
  display: flex;
}

.betaTag {
  bottom: 22px;
  left: 8px;
  position: relative;

     .beta::after {
     content: "Beta";
     text-transform: none;
}
}

.pageContentContainer {
  background-color: white;
  border-radius: 4px;
  padding: 16px;
}

.stickyLeft {
  position: sticky;
  left: 0;
}

body {
  overflow-x: hidden !important;
}

.initialPageLoader {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
}

.readOnlyInput {
  input {
    pointer-events: none !important;
    &:disabled {
      color: var(--tds-text-field-data-color) !important;
    }
  }

  tds-icon {
    cursor: pointer;
    color: var(--tds-text-field-ps-color) !important;
  }
  
  label {
    color: var(--tds-text-field-data-color) !important;
  }
}

.exportExcelButton {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

@media screen and (width < 1200px) {
  .exportExcelButton {
    justify-content: flex-start;
  }
}

.stickyHeader {
  position: sticky;
  z-index: 1000;
}

.emptyDashboardContainer {
  background-color: white;
  border-radius: 4px;
  padding: 64px 0;
  text-align: center;
}

.emptyDashboardContent {
  display: flex;
  justify-content: center;
  text-align: center;

  .rightSection {
    text-align: left;
    margin-left: 12px;
  }

  .addWidgetButton {
    margin-top: 24px;
  }

  .emptyDashboardTitle {
    margin-bottom: 8px;
  }
}

.badge {
  align-items: center;
  background: var(--tds-blue-400);
  border-radius: 1.4rem;
  color: white;
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  height: auto;
  justify-content: center;
  min-height: 8px;
  min-width: 8px;
  padding: 0;
  position: absolute;
  right: 12px;
  top: 17px;
  width: auto;
  z-index: 1002 !important;
}