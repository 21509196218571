.headerItemDivider {
  border-right: 1px solid;
  width: 15px;
  height: 20px;
  margin: 22px 0px 0px 0px;
}

.groupsDropdownContainer {
  margin-left: 16px;
  width: 208px;
}

.dropdownsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 16px;
  margin-top: -2px;
  }

#shepherd-views-container, #shepherd-filters-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.viewsDropdownContainer {
  width: 300px;
  margin-right: var(--tds-spacing-element-16);
}

.tableContainer {
  overflow: auto;
  max-height: calc(100vh - 385px);

  &::-webkit-scrollbar:horizontal{
    height: 7px;
  }

  .stickyTopHeaderParam {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .stickyTopUnit {
    position: sticky;
    top: 48px;
    z-index: 10;
  }

  .firstColumnZIndex {
    z-index: 20;
  }
}

.overviewDatePickerContainer {
  display: flex;
  gap: 16px;
  height: 48px;
  align-items: center;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
}

.createViewButton {
  margin: 0 24px 0 16px;
}

.dividerContainer {
  height: 40px;
  width: 1px;
  margin-right: 24px;
}

.buttonTooltip {
  z-index: 21;
}

@media screen and (width < 1200px) {
  .dividerContainer {
    display: none;
  }

  .overviewDatePickerContainer {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }
}

.isDropdownTitle {
  font: var(--tds-detail-06) !important;
  font-weight: 700 !important;
  background-color: var(--tds-grey-100) !important;
  color: rgba(0, 0, 0, 0.6) !important;
  pointer-events: none;
}

.isDropdownTitleText {
  font: var(--tds-detail-06) !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.6);
  pointer-events: none;
}

.overviewHeader {
 margin: 20px 0px 0px 0px;
}