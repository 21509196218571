.filtersContainer {
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  position: relative;
  margin-bottom: 24px;
}

.filtersHeader {
  margin: 0px;
}

.filtersCloseIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.filtersDropdowns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
