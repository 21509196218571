.dashboardPageContainer {
  border-radius: 4px;
  padding-top: 24px
}

.inlineFlexContainer {
  width: auto;
  display: inline-flex;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 16px;
}

.groupsDropdownContainer {
  width: 256px;
}

.flexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.groupsDropdownContainer {
  width: 256px;
  position: relative;
}

.groupsDropdownWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.groupsDropdownLabel {
  color: var(--tds-dropdown-label-color);
  font: var(--tds-detail-05);
}

.filtersButtonContainer {
  display: flex;
  align-items: center !important;
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.filterBadge {
  color: #fff !important;
  background-color: var(--tds-blue-400) !important;
  border-radius: 50%;
  padding: 4px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  margin-left: 66px;
  margin-top: -42px;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: 4px;
}

.clearFiltersButton {
  display: flex;
  margin-top: 28px;
}

.dividerWrapper {
 margin-bottom: 56px;
}

.createDashboardButton {
  align-self: end;
}

.dashboardHeader {
  margin: 0;
}

.clearfiltersLink {
  margin-bottom: 12px;
}
