.tableWidgetHeader {  
  font: var(--tds-headline-05);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 104px;
}

.tableWidgetControls {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.leftActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.rightActions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 24px;
}

.iconContainer {
  position: relative;
  top: -28px;
  display: flex;
  gap: 12px;
}

.movedIcon {
  margin-top: 2px;
}

.buttonTooltip {
  z-index: 1000;
}

.tableWidgetActions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.searchField {
  width: 300px;
}

.searchFieldContainer {
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  flex-grow: 1;
}

.dividerContainer {
  height: 40px;
  width: 1px;
}

.tableWidgetContainer {
  background-color: var(--tds-white);
  border-radius: 4px;
  margin-bottom: var(--tds-spacing-element-24);
  padding: var(--tds-spacing-element-16);
}

.tableWidget {
  max-height: 500px;
  overflow: auto;
}

.skeletonContent {
  min-height: 100px;
  width: 100%;
}

.tableWidget {
  &::-webkit-scrollbar:horizontal{
    height: 7px;
  }
}

.leftAlignedText {
  text-align: left !important;
}